define('settings',[
  'module',
  'jquery',
  'underscore',
  'jqueryCookie',
  'envHandler',
  'protocol',
  'browserUtils'
], function (module, $, _, JqueryCookie, EnvironmentHandler, Protocol, BrowserUtils) {
  'use strict';

  var env = 'zaprintabeta';

  var _default = {

    urls: {
      compuzzWs: 'ws2/rest/',
      compuzzBase: 'compuzz/',
      dl: EnvironmentHandler.getValue(env, 'downloadUrl'),
      compuzz: 'compuzz/rest/',
      compuzz2: 'compuzz2/rest/',
      compuzz3: 'compuzz3/rest/',
      compuzzHtml: 'compuzz/plugin/compuzzhtml/',
      compuzzHtmlCustomization: 'htmlcompuzz/#customization/',
      compuzzPlugin: 'compuzz/plugin/index.jsf',
      compuzzProductPlugin: 'compuzz/productPlugin/index.jsf',
      compuzzOrderProcess: 'compuzz/orderprocess/',
      compuzzTranslate: 'https://api.microsofttranslator.com/v2/Http.svc/Translate',
      compuzzApi: 'https://bxbp3ch1m7.execute-api.eu-west-1.amazonaws.com/prod',
      compuzzPlivo: 'https://gd0fj5e861.execute-api.eu-west-1.amazonaws.com/test',
      pluginUrl: 'http://127.0.0.1:52005/executeAction', //DEV with separate VM: change to http://pluginair.lan:52005/executeAction
      pdf: 'compuzz2/PdfGenerator',
      x2: 'x2pdf-ws/rest/convert/',
      x2Ws: 'x2-ws/',
      x2pdf: 'x2pdf-ws/rest/',
      x2png: EnvironmentHandler.getValue(env, 'nodeUrl') || 'x2png-ws/',
      file: EnvironmentHandler.getValue(env,
        'filesPath') + 'getfile/'
    },
    imageSize: {
      thumbnail: 't',
      medium: 'm',
      big: 'b'
    },
    compuzzCreateAccountUrl: {
      fr: 'https://fr.compuzz.com/creer-un-compte',
      nl: 'https://nl.compuzz.com/een-account-aanmaken',
      de: 'https://de.compuzz.com/neues-konto-anlegen',
      es: 'https://es.compuzz.com/crea-una-cuenta',
      en: 'https://www.compuzz.com/create-an-account'
    },
    compuzzLoginAccountUrl: {
      fr: 'https://fr.compuzz.com/se-connecter',
      nl: 'https://nl.compuzz.com/aanmelden',
      de: 'https://de.compuzz.com/anmelden',
      es: 'https://es.compuzz.com/conectate',
      en: 'https://www.compuzz.com/connect'
    },
    environment: env,
    tokenId: '',
    perPage: 10,
    perPage15: 15,
    pageNumber: 1,
    application: 'dashboard',
    textSupport: 'support',
    orderTextSupport: 'ordersupport',
    lang: BrowserUtils.getLanguage(),
    compuzzLogo: EnvironmentHandler.getValue(env, 'compuzzLogo'),
    defaultLogo: EnvironmentHandler.getValue(env, 'defaultLogo'),
    notFoundImage: EnvironmentHandler.getValue(env, 'notFoundImage'),
    defaultCatalogueImage: EnvironmentHandler.getValue(env, 'catalogueDefaultImage'),
    gmailClientId: EnvironmentHandler.getValue(env, 'gmailClientId'),
    gmailAPIKey: EnvironmentHandler.getValue(env, 'gmailAPIKey'),
    config: EnvironmentHandler.getValue(env, 'config'),
    websitePrefix: 'http://',
    websitePostfix: '.logotool.compuzz.com',
    accountsPageSize: 15,
    customersPageSize: 15,
    lazyLoadingSize: 10,
    minBalance: 20,
    supportEmail: 'support@compuzz.com',
    googleClientId: '853426927905-94mqbg6o7m3ph0u8v1nhphbea7106jmr.apps.googleusercontent.com',
    translateClientApiId: '29ABC175-452C-4e6a-B625-AFDC9C314B39',
    stripeApiKey: EnvironmentHandler.getValue(env, 'stripeApiKey'),
    version: 'DEV-VERSION'
  };

  var Settings = {
    orderColors: {},
    offerColors: {},
    orderColorsInvoice: {},
    offerColorsInvoice: {},
    isDebugMode: false,
    tplEngine: 'html',
    storeInCookies: [
      'plivoUser', 'plivoPassword', 'tokenId', 'currentUserCookie', 'currentCAccountCookie', 'lastUrlPageCookie', 'lang',
      'userFileExtension', 'lastMsgTab', 'boardSort'
    ],
    storeInMemory: {},
    numbers: ['perPage', 'pageNumber'],
    serverUrl: Protocol.apply(EnvironmentHandler.getValue(env,
      'serverUrl')),
    domaine: '.com/',
    compuzzAwsWs: EnvironmentHandler.getValue(env, 'awsWebSocket'),
    compuzzAwsWsSend: EnvironmentHandler.getValue(env, 'awsWebSocketSend'),
    compuzzProProtocol: EnvironmentHandler.getValue(env, 'compuzzProProtocol'),
    compuzzEnv: EnvironmentHandler.getValue(env, 'compuzzEnv'),
    dl: EnvironmentHandler.getValue(env, 'downloadUrl'),
    cacheGetSet: {},
    editorUrl: EnvironmentHandler.getValue(env, 'editorUrl'),
    webshopUrl: EnvironmentHandler.getValue(env, 'webshopUrl'),

    windows: {
      gallery: {
        width: 1024,
        height: 600        
      },
      pdf: {
        width: 1024,
        height: 600,
        margin: false
      },
      tracking: {
        width: 400,
        height: 400,
        minWidth: 400,
        minHeight: 400
      },
      customerEdit: {
        width: '80%',
        height: '80%',
        modal: true,
        margin: false
      },
      fullScreen: {
        width: '95%',
        height: '90%',
        margin: false
      },
      newTask: {
        width: 600,
        height: 500,
        minWidth: 600,
        minHeight: 500,
        modal: true,
        margin: false
      },
      commonTask: {
        width: 600,
        height: 500,
        minWidth: 600,
        minHeight: 500,
        margin: false
      },
      callRecord: {
        width: 600,
        height: 300,
        minWidth: 600,
        minHeight: 300,
        margin: false
      }
    }, 

    pluginUrl: function () {
      return 'http://127.0.0.1:52005/executeAction'; //DEV with separate VM: change to http://pluginair.lan:52005/executeAction
    },

    set: function (name, value, storeInCookies) {
      if (!name) {
        return;
      }
      if (name === 'sessionId' && !value) {
        return;
      } else if (storeInCookies || _.contains(this.storeInCookies, name)) {
        if (!_.isEmpty(value) || value) {
          value = value.toString();
          $.cookie(name, value, {expires: 30});
          this[name] = value;
        } else {
          $.removeCookie(name);
          delete this[name];
        }
      } else {
        this[name] = value;
      }
      this.cacheGetSet[name] = value;
    },


    get: function (name, fromCookies) {
      var option;
      if (this.cacheGetSet[name]) {
        option = this.cacheGetSet[name];
      } else if (fromCookies || _.contains(this.storeInCookies, name)) {
        option = $.cookie(name);
        if (option === 'false') {
          option = false;
        }
      }
      option = option || _default[name];
      if (_.contains(this.numbers, name)) {
        option = parseInt(option, 10);
      }
      if (name === 'path' && option) {
        option = option.split(',');
      }
      if (this[name]) {
        option = this[name];
      }
      this.cacheGetSet[name] = option;
      return option;
    },

    url: function (base, relative, params, tokenNotRequired) {
      var baseUrl = '',
        baseServerUrl = '',
        indexOfServerUrl = -1,
        stringParams = '',
        ampersand = '',
        useServerUrl = true;

      if (relative) {
        indexOfServerUrl = relative.indexOf('http://localhost:8080/');
        if (indexOfServerUrl >= 0) {
          baseServerUrl = 'http://localhost:8080/';
        } else {
          indexOfServerUrl = relative.indexOf(this.serverUrl || '');
          if (indexOfServerUrl >= 0) {
            baseServerUrl = this.serverUrl;
          } else {
            indexOfServerUrl = relative.indexOf(this.domaine);

            var hasArobase = relative.indexOf('@');
            if (indexOfServerUrl >= 0 && (hasArobase < 0 || hasArobase > indexOfServerUrl)) {
              baseServerUrl = relative.substring(0, indexOfServerUrl + this.domaine.length);
            }
          }
        }
        if (indexOfServerUrl >= 0) {
          relative = relative.replace(baseServerUrl, '');
        }

      }
      params = params || {};

      if (params.size) {
        params.action = 'res';
        params.params = _default.imageSize[params.size];
        delete params.size;
      }

      if (base === 'base') {
        baseUrl = '';
      } else if (base === 'webshop') {
        baseUrl = 'webshop/';
        useServerUrl = false;
      } else {
        baseUrl = _default.urls[base] || base;
        if (baseUrl === _default.urls.compuzzTranslate) {
          useServerUrl = false;
          params.appid = _default.translateClientApiId;
        } else if (baseUrl.indexOf('https://') === 0 || baseUrl === _default.urls.pluginUrl) {
          useServerUrl = false;
        }
      }

      if (relative) {
        if (relative.indexOf('?') === -1) {
          stringParams = '?';
        } else {
          stringParams = '&';
        }
      } else {
        stringParams = '?';
      }

      if(base !== 'webshop') {
        if (!params.lang) {
          params.lang = this.get('lang');
        }
      }

      _.each(params, function (value, key) {
        if ((value || value === 0) && key) {
          if (key === 'poi' && typeof value === 'object') {
            _.each(value, function (value) {
              stringParams += (ampersand + key + '=' + value.toString());
              ampersand = '&';
            });
          } else {
            stringParams += (ampersand + key + '=' + value.toString());
            ampersand = '&';
          }
        }
      });

      if(base === 'webshop') {
        stringParams += ampersand + 'tokenid' + '=' + Settings.configValue('webshopApiTokenId');
      } else {
        if (baseUrl.indexOf('getfile') < 0 && !tokenNotRequired) {
          stringParams += ampersand + 'tokenId' + '=' + this.get('tokenId');
        }
      }

      return (useServerUrl === true ? Protocol.apply(this.serverUrl) : '') + baseUrl + relative + stringParams;
    },

    setToMemory: function (key, value) {
      value = JSON.stringify(value);

      if (key) {
        if (_.isEmpty(value) || !value) {
          delete Settings.storeInMemory[key];
        } else {
          Settings.storeInMemory[key] = value;
        }
      }
    },

    clearMemory: function () {
      Settings.storeInMemory = {};
    },

    getFromMemory: function (key) {
      var value = Settings.storeInMemory[key];
      if (value) {
        value = JSON.parse(value);
      }

      return value;
    },

    isMultiWebshop: function () {
      var wsUrl = Settings.get('webshopUrl');
      if (wsUrl && typeof wsUrl !== 'string' && !(wsUrl instanceof String)) {
        return true;
      }
      return false;
    },

    getWebshopUrl: function (lang, countryCode) {
      var wsUrl = Settings.get('webshopUrl');
      if (wsUrl) {
        if (typeof wsUrl === 'string' || wsUrl instanceof String) {
          return wsUrl;
        } else {
          if (lang && countryCode) {
            wsUrl = Settings.get('webshopUrl')[lang.toLowerCase() + '_' + countryCode.toUpperCase()];
          } else {
            wsUrl = null;
          }
          if (!wsUrl) {
            wsUrl = Settings.get('webshopUrl')[lang.toLowerCase()];
          }
          if (!wsUrl) {
            wsUrl = Settings.get('webshopUrl').fr;
          }
          return wsUrl;
        }
      }
    },

    getWebshopLocales: function () {
      var webshops = [];
      for (var prop1 in Settings.get('webshopUrl')) {
        if (prop1.indexOf('_') !== -1) {
          webshops.push({
            code: prop1,
            value: Settings.get('webshopUrl')[prop1]
          });
        }
      }
      return webshops;
    },

    configValue: function (params) {
      var config = Settings.get('config');
      if (config && params) {
        var paramsArr = params.split('.');
        var curr = config;
        for (var i = 0; i < paramsArr.length; i++) {
          curr = curr[paramsArr[i]];
          if (!curr) {
            return false;
          }
          if (i === paramsArr.length - 1) {
            return curr;
          }
        }
      }
      return false;
    },

    getConfig: function (params, defaultValue) {
      var config = Settings.get('config');
      if (config && params) {
        var paramsArr = params.split('.');
        var curr = config;
        for (var i = 0; i < paramsArr.length; i++) {
          curr = curr[paramsArr[i]];
          if (!curr) {
            return defaultValue;
          }
          if (i === paramsArr.length - 1) {
            return curr;
          }
        }
      }
      return defaultValue;
    },

    getWebshopProofSheetUrl: function (lang, countryCode, poi, tracking, pdf) {
      var webshopBaseUrl = Settings.getWebshopUrl(lang, countryCode);

      if (pdf) {
        return webshopBaseUrl + '/proofsheet/pdf/' + poi;
      } else if (tracking) {
        return webshopBaseUrl + '/proofsheet/' + poi;
      } else {
        return webshopBaseUrl + '/proofsheet/notrack/' + poi;
      }
    }
  };

  module.exports = Settings;
});

